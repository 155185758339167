<template>
    <BaseSection>
        <div class="card mt-2">
            <div class="card-body">
                <h1>Build the job</h1>
                <p>Add criteria - see number of matches</p>
                <Keywords :skills="skills" @change="updateSkills" placeholder="e.g. PHP" />

                <div class="text-center">
                    <h1 class="text-center">{{ numberOfCandidates }}</h1>
                    <h2 class="text-center">Candidates ready to work for you</h2>

                    <RouterLink :to="{ name: ROUTE.SIGN_IN }">
                        <BButton class="mt-2" variant="success" size="lg">Sign Up Now</BButton>
                    </RouterLink>
                </div>
            </div>
        </div>
    </BaseSection>
</template>

<script>
import { BaseSection } from '@/components'
import { Keywords } from '@/components/SkillsBuilder'
import { ROUTE } from '@/enums'

export default {
    name: 'AddJobShowcase',

    components: { BaseSection, Keywords },

    data() {
        return {
            ROUTE,
            skills: [],
            numberOfCandidates: 0,
        }
    },

    computed: {
        keywords() {
            return this.skills.map(({ name }) => name).join(', ')
        },
    },

    watch: {
        keywords() {
            this.fetchCandidates()
        },
    },

    mounted() {
        this.fetchCandidates()
    },

    methods: {
        updateSkills(skills) {
            this.skills = skills
        },

        fetchCandidates() {
            this.$store.state.backend
                .get(`/recruiter/candidatesForJob?skills=${this.keywords}`)
                .then((res) => (this.numberOfCandidates = res.data.exact.length))
        },
    },
}
</script>
